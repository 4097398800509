import React, { useContext } from "react";
import CustomerRow from "./CustomerRow";
import SortableHeader from "./SortableHeader";
import { UserContext } from "../../context/UserContext";

const CustomersTable = ({
  customers,
  extraFields,
  setSort,
  selected,
  direction,
  setDirection,
  showCancelReason,
}) => {
  const { user } = useContext(UserContext);
  
  const renderCustomers = () => {
    if (Array.isArray(customers)) {
      if (customers.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay clientes registradas.</td>
          </tr>
        );
      }
      return customers.map((customer) => {
        
       return (<CustomerRow
          customer={customer}
          key={customer.customer_id}
          extraFields={extraFields}
          showCancelReason={showCancelReason}
        />
      )});
    }
  };

  const renderExtraFields = () => {
    if (Array.isArray(extraFields)) {
      return extraFields.map((field) => <td key={field}>{field.label}</td>);
    }
    if (showCancelReason) {
      return <td>Razón</td>;
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <SortableHeader
              column="customer_id"
              label="#ID"
              selected={selected}
              setSort={setSort}
              direction={direction}
              setDirection={setDirection}
            />
            <SortableHeader
              column="name"
              label="Nombre"
              selected={selected}
              setSort={setSort}
              direction={direction}
              setDirection={setDirection}
            />
            <SortableHeader
              column="email"
              label="Correo"
              selected={selected}
              setSort={setSort}
              direction={direction}
              setDirection={setDirection}
            />
            <SortableHeader
              column="phone"
              label="Teléfono"
              setSort={setSort}
              selected={selected}
              direction={direction}
              setDirection={setDirection}
            />
            <SortableHeader
              column="birthdate"
              label="Cumpleaños"
              setSort={setSort}
              selected={selected}
              direction={direction}
              setDirection={setDirection}
            />
            {["super_admin", "admin"].includes(user.role) && <SortableHeader
              column="value"
              label="Valor"
              setSort={setSort}
              selected={selected}
              direction={direction}
              setDirection={setDirection}
            />}
            {renderExtraFields()}
          </tr>
        </thead>
        <tbody>{renderCustomers()}</tbody>
      </table>
    </div>
  );
};

export default CustomersTable;
