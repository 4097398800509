import { Link } from "@reach/router";
import React, { useContext, useEffect } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import { CustomerContext } from "../../context/CustomerContext";
import FileInput from "../common/FileInput";

const CustomerForm = ({ customer_id }) => {
  const {
    spinner,
    customer,
    getCustomer,
    postCustomer,
    createCustomer,
    setPropiedadCustomer,
  
  } = useContext(CustomerContext);

  const { business_name } = useContext(AppConfigContext);

  useEffect(() => {
    if (isNaN(customer_id)) {
      createCustomer();
    } else {
      getCustomer(customer_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    customer.email = String(customer.email).trim();
    postCustomer(customer);
  };

  const renderForm = () => {
    if (customer && customer !== null) {
      return (
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-6">
            <label>Nombre</label>
            <input
              type="text"
              className="form-control mb-3"
              value={customer.name}
              onChange={(e) => setPropiedadCustomer("name", e.target.value)}
            />
          </div>
          <div className="col-6">
            <label>Apellidos</label>
            <input
              type="text"
              className="form-control mb-3"
              value={customer.last_name}
              onChange={(e) => setPropiedadCustomer("last_name", e.target.value)}
            />
          </div>
          <div className="col-6">
            <label>Correo Electrónico</label>
            <input
              type="text"
              className="form-control mb-3"
              value={customer.email}
              onChange={(e) => setPropiedadCustomer("email", e.target.value)}
            />
          </div>
          <div className="col-6">
            <label>Teléfono</label>
            <input
              type="text"
              className="form-control mb-3"
              value={customer.phone}
              onChange={(e) => setPropiedadCustomer("phone", e.target.value)}
            />
          </div>
          <div className="col-6">
            <label>Instagram</label>
            <input
              type="text"
              className="form-control mb-3"
              value={customer.instagram}
              onChange={(e) => setPropiedadCustomer("instagram", e.target.value)}
            />
          </div>
          <div className="col-6">
            <label>¿Cómo te enteraste de {business_name}?</label>
            <input
              type="text"
              className="form-control mb-3"
              value={customer.signup_reason}
              onChange={(e) =>
                setPropiedadCustomer("signup_reason", e.target.value)
              }
            />
          </div>

          <div className="col-6">
            <FileInput
              label={'Imagen de Usuario'}
              value={customer.file}
              modifierKey={'newFile'}
              modifier={setPropiedadCustomer}
              type={'image'}
            />
          </div>

          <div className="row">
            <div className="col-6">
              <button className="btn btn-primary">
                {spinner ? <div className="spinner-border"></div> : "Guardar"}
              </button>
            </div>
            <div className="col-6 text-right">
              <Link
                to="/myadmin/customers"
                className="btn btn-link text-secondary"
              >
                Cancelar
              </Link>
            </div>
          </div>
        </form>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return <div>{renderForm()}</div>;
};

export default CustomerForm;
