import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";

const Footer = () => {
  const { business_name } = useContext(AppConfigContext);
  return (
    <div className="footer container-fluid bg-dark py-3 text-center text-white">
      &copy; 2022 {business_name}
    </div>
  );
};

export default Footer;
