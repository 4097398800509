import React, { useContext, useEffect, useState } from "react";
import HeaderRow from "../../components/global/HeaderRow";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import { LocationsContext } from "../../context/LocationsContext";
import Chart from "react-apexcharts";
import ReactSelect from "react-select";
import moment from "moment";

const AnaliticasReservaciones = () => {
  const [startDate, setStartDate] = useState(
    moment().startOf("week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().startOf("week").add(2, "weeks").format("YYYY-MM-DD")
  );

  const [currentLocation, setCurrentLocation] = useState({
    label: 'Todas las Ubicaciones',
    value: null
  });
  const [selectOptions, setSelectOptions] = useState([]);

  const { reservaciones, horas, days, getReservaciones } =
    useContext(AnaliticasContext);
  const { locations, getLocations } = useContext(LocationsContext);

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    if(locations) {
      const opts = locations?.map(loc => {
        return {
          value: loc.location_id,
          label: loc.name,
        }
      });
  
      setSelectOptions([
        {
          label: 'Todas las Ubicaciones',
          value: null
        },
        ...opts
      ]);
    }
  }, [locations]);

  useEffect(() => {
    getViewData();
  }, [endDate, startDate, currentLocation]);

  const getViewData = () => {
    const filters = {
      start_date: startDate,
      end_date: endDate,
      location_id: currentLocation.value
    }
    getReservaciones(filters);
  }

  


  const renderChart = () => {
    if (Array.isArray(horas) && horas.length > 0) {
      let data = [...horas];
      data = data.sort((a, b) => (a.class_hour > b.class_hour ? 1 : -1));
      return (
        <Chart
          type="bar"
          height="300"
          options={{
            colors: ["#094f7f", "#f84f10", "#c6c0c1", "#000"],
            xaxis: {
              categories: data.map(({ class_hour }) => class_hour),
            },
            yaxis: {
              labels: {
                formatter: (val) => parseInt(val),
              },
            },
          }}
          series={[
            {
              name: "Capacidad Total por Horario",
              data: data.map(({ capacity }) => capacity),
            },
            {
              name: "Reservadas por Horario",
              data: data.map(({ bookings }) => bookings),
            },
            {
              name: "Asistidas por Horario",
              data: data.map(({ attended }) =>
                attended === null ? 0 : attended
              ),
            },
            {
              name: "Clases por Horario",
              data: data.map(({ single_classes }) => single_classes),
            },
          ]}
        />
      );
    }
  };

  const renderChartCapacity = () => {
    if (horas && horas !== null) {
      let data = [...horas];
      data = data.sort((a, b) => (a.class_hour > b.class_hour ? 1 : -1));
      return (
        <Chart
          type="bar"
          height="300"
          options={{
            colors: ["#094f7f", "#f84f10", "#c6c0c1", "#000"],
            xaxis: {
              categories: data.map(({ class_hour }) => class_hour),
            },
            yaxis: {
              labels: {
                formatter: (val) => parseInt(val),
              },
            },
            dataLabels: {
              formatter: (val) => `${val}%`,
            },
          }}
          series={[
            {
              name: "Ocupación por Horario",
              data: data.map(({ capacity, bookings }) =>
                (
                  parseFloat(
                    parseFloat(bookings) / parseFloat(capacity)
                  ).toFixed(2) * 100
                ).toFixed(2)
              ),
            },
          ]}
        />
      );
    }
  };

  const renderChartDays = () => {
    if (Array.isArray(days) && days.length > 0) {
      let data = [...days];
      data = data.sort((a, b) => (a.day_number > b.day_number ? 1 : -1));
      data.unshift(data.pop());
      return (
        <Chart
          type="bar"
          height="300"
          options={{
            colors: ["#094f7f", "#f84f10", "#c6c0c1", "#000"],
            xaxis: {
              categories: data.map(({ day_name }) => day_name),
            },
          }}
          series={[
            {
              name: "Reservaciones por Día",
              data: data.map(({ bookings }) => bookings),
            },
            {
              name: "Capacidad por Día",
              data: data.map(({ capacity }) => capacity),
            },
            {
              name: "Asistidas por Día",
              data: data.map(({ attended }) => attended),
            },
            {
              name: "Clases por Día",
              data: data.map(({ single_classes }) => single_classes),
            },
          ]}
        />
      );
    }
  };

  const renderReservaciones = () => {
    if (reservaciones && reservaciones !== null) {
      return reservaciones.map((tipo, index) => (
        <div key={index} className="row p-2">
          <div className="col">{tipo.name}</div>
          <div className="col">{tipo.booked}</div>
          <div className="col">{tipo.attended}</div>
        </div>
      ));
    }
  };

  return (
    <div className="container-fluid px-3">
      <div className="row mx-0 align-items-center mb-3 pb-2 border-bottom">
        <div className="col-12 px-0">
          <h1 className="h2 bold mb-0">Reservaciones</h1>
        </div>

        <div className="col-12 col-md-11 col-xl-8 offset-md-1 offset-xl-4  px-0 text-end mb-2">
          <div className="row align-items-center">
            <div className="col-6">
              <ReactSelect
                className="text-start"
                value={currentLocation}
                options={selectOptions}
                onChange={newValue => setCurrentLocation(newValue)} 
              />
            </div>

            <div className="col-3">
              <input
                type="date"
                value={startDate}
                className="form-control"
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="col-3">
              <input
                type="date"
                value={endDate}
                className="form-control"
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>

 

          </div>
        </div>
      </div>

      <div className="card  shadow-sm my-3 p-3">
        <h4 className="border-bottom pb-3 mb-2">Estadísticas por Horario</h4>
        {renderChart()}
      </div>
      <div className="card  shadow-sm my-3 p-3">
        <h4 className="border-bottom pb-3 mb-2">
          Estadísticas por Día de la Semana
        </h4>
        {renderChartDays()}
      </div>
      <div className="card  shadow-sm my-3 p-3">
        <h4 className="border-bottom pb-3 mb-2">
          Procentaje de Ocupación por Horario
        </h4>
        {renderChartCapacity()}
      </div>
      <div className="row">
        <div className="container-fluid">
          <div className="card p-3  me-3">
            <h4>Reservaciones por Tipo de Clase</h4>
            <HeaderRow headers={["Nombre", "Reservadas", "Asistidas"]} />
            {renderReservaciones()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnaliticasReservaciones;
