import React, { useContext, useEffect, useState } from "react";
import MapaLugares from "../clases/MapaLugares";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import { getValue, hideModal } from "../../utils";
import ImageInput from "../common/ImageInput";
import { AppConfigContext } from "../../context/AppConfigContext";
import IconPicker from "../global/IconPicker";
import ReactSwitch from "react-switch";
import useAppConfigValues from "../../hooks/useAppConfigValues";

const ClassTypeForm = ({ class_type_id }) => {
  const {
    class_type,
    getClassType,
    createClassType,
    setPropiedadClassType,
    postClassType,
  } = useContext(ClassTypeContext);

  const { singleClassEnabled, allowRestrictedClassTypes } = useAppConfigValues();

  useEffect(() => {
    if (isNaN(class_type_id)) {
      createClassType();
    } else {
      getClassType(class_type_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_type_id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    postClassType(class_type);
  };

  const renderRestrictedSwitch = () => {
    if(allowRestrictedClassTypes()) {
      return (
        <div className="row my-2">
          <div className="col-6">
            <label>Restringir Reservaciones</label>
          </div>
          <div className="col-6 text-end">
            <ReactSwitch
              checked={getValue(class_type, "restricted", "boolean")}
              onChange={(checked) =>
                setPropiedadClassType("restricted", checked)
              }
            />  
          </div>       
        </div>
      )
    }
  }


  const renderForm = () => {
    if (class_type && class_type !== null) {
      const { name, description, icon, color, spot_map, file } = class_type;
      let lugares = String(spot_map)
        .split(",")
        .map((num) => parseInt(num))
        .filter((num) => !isNaN(num));
      return (
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => setPropiedadClassType("name", e.target.value)}
          />
          <label>Descripción</label>
          <input
            type="text"
            className="form-control mb-3"
            value={description}
            onChange={(e) =>
              setPropiedadClassType("description", e.target.value)
            }
          />
          <ImageInput
            value={file}
            prop="picture"
            modifier={setPropiedadClassType}
          />

          <div className="row ">
            <label>Icono</label>
            <IconPicker
              selectedIcon={icon}
              setSelectedIcon={(iconName) => {
                setPropiedadClassType('icon', iconName);
              }}
            />
          </div>

          {renderRestrictedSwitch()}

          <label>Color</label>
          <input
            type="color"
            className="form-control mb-3"
            value={color}
            onChange={(e) => setPropiedadClassType("color", e.target.value)}
          />
          {singleClassEnabled() && (
            <div>
              <label>Lugares</label>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Número de columnas por fila, separado por comas"
                value={spot_map}
                onChange={(e) =>
                  setPropiedadClassType("spot_map", e.target.value)
                }
              />
            </div>
          )}
          {lugares.length > 0 && <MapaLugares rows={lugares} icon={icon} />}
          <div className="row">
            <div className="col-6">
              <button type="submit" className="btn btn-primary w-100 mt-3">
                Guardar
              </button>
            </div>
            <div className="col-6">
              <button
                type="button"
                onClick={hideModal}
                className="btn btn-link text-muted w-100 mt-3"
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default ClassTypeForm;
