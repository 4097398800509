import React, { useState, useEffect, useContext } from "react";
import { ClassInstructorContext } from "../../context/SingleClassContext";
import WeekScheduleAdminDesktop from "./WeekScheduleAdminDesktop";
import { UserContext } from "../../context/UserContext";
import ScheduleMonthSelect from "./ScheduleMonthSelect";
import ScheduleWeekSelect from "./ScheduleWeekSelect";
import MonthScheduleAdmin from "./MonthScheduleAdmin";
import WeekScheduleMobile from "./WeekScheduleMobile";
import utils from "./utils";
import "./schedule.css";

const ScheduleAdmin = () => {
  const [view, setView] = useState("week");
  const [currentClasses, setCurrentClasses] = useState(null);

  const {
    days,
    update,
    end_date,
    start_date,
    setEndDate,
    getSchedule,
    clearUpdate,
    setStartDate,
  } = useContext(ClassInstructorContext);

  const { user } = useContext(UserContext);

  useEffect(() => {
    const date = utils.getStartDate(view);
    setStartDate(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  useEffect(() => {
    setEndDate(utils.getEndDate(view, start_date));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date]);

  useEffect(() => {
    if (Array.isArray(days)) {
      if (view === "week") {
        setCurrentClasses(days.slice(0, 7));
      } else {
        setCurrentClasses(days);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  useEffect(() => {
    fetchSchedule();
    clearUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date, end_date, update]);

  const fetchSchedule = () => {
    const params = {};
    
    if (utils.validDates(start_date, end_date)) {
      params.start_date = start_date;
      params.end_date = end_date;
    }
    if (user.role === "coach") {
      params.instructor_id = user.instructor_id;
    }
    getSchedule(params);
  };

  const renderScheduleView = () => {
    if (view === "week") {
      return (
        <div>
          <WeekScheduleAdminDesktop
            weekClasses={currentClasses}
            handleCallback={fetchSchedule}
          />
          <WeekScheduleMobile
            weekClasses={currentClasses}
            handleCallback={fetchSchedule}
          />
        </div>
      );
    }
    return (
      <MonthScheduleAdmin
        monthClasses={currentClasses}
        handleCallback={fetchSchedule}
      />
    );
  };

  const handleNextWeek = () => {
    setStartDate(utils.getStartNext("week", start_date));
  };

  const handlePrevWeek = () => {
    setStartDate(utils.getStartPrev("week", start_date));
  };

  const handleNextMonth = () => {
    setStartDate(utils.getStartNext("month", start_date));
  };

  const handlePrevMonth = () => {
    setStartDate(utils.getStartPrev("month", start_date));
  };

  return (
    <div className="container-fluid px-0" style={{ overflowX: "hidden" }}>
      <div className="container-fluid px-0">
        <div className="row align-items-center mt-3 mb-4">
          <div className="col-12 col-md-4 mb-3">
            <ScheduleMonthSelect
              start_date={start_date}
              handleNext={handleNextMonth}
              handlePrev={handlePrevMonth}
            />
          </div>
          <div className="col-12 col-md-4 mb-3">
            <ScheduleWeekSelect
              start_date={start_date}
              handleNextWeek={handleNextWeek}
              handlePrevWeek={handlePrevWeek}
            />
          </div>
          <div className="col-12 col-md-4 mb-3 text-end">
            <div className="btn-group">
              <button
                onClick={() => setView("week")}
                className={`btn border btn-${
                  view === "week" ? "primary" : "light"
                }`}
              >
                Semana
              </button>
              <button
                onClick={() => setView("month")}
                className={`btn border btn-${
                  view === "month" ? "primary" : "light"
                }`}
              >
                Mes
              </button>
            </div>
          </div>
        </div>
      </div>
      {renderScheduleView()}
    </div>
  );
};

export default ScheduleAdmin;
