import React, { createContext, useContext, useEffect, useReducer } from "react";
import AppConfigReducer from "../reducers/AppConfigReducer";
import AppConfigService from "../services/AppConfigService";
import { SET_CONFIG, SET_PROPERTY } from "../types/appconfig";
import { ModalContext } from "./ModalContext";

const initialState = {
  fileConfigChanged: false,
  loading: false,
  signup_bonus: null,
  business_name: '',
  tagline: '',
  primary: '',
  accent: '',
  dark: '',
  light: '',
  gray: '',
  favicon: '',
  social_media_image: '',
  navbar_logo: '',
  files_location: 'server',
  aws_bucket_name: '',
  aws_access_key: '',
  aws_secret_key: '',
  aws_bucket_region: '',
  head_tracking_codes: '',
  body_tracking_codes: '',
  cancel_timeframe_value: 0,
  cancel_timeframe_unit: 'hours',
  reservation_timeframe_hours: 0,
  reservation_timeframe_minutes: 0,
  stripe_secret: '',
  stripe_webhook_secret: '',
  stripe_public_key: '',
  allow_class_guess: false,
  location : '',
  email: '',
  facebook: '',
  instagram: '',
  tiktok: '',
  twitter: '',
  youtube: '',
  pinterest: '',
  linkedin: '',
  whatsapp: '',
  phone: '',
  whatsapp_widget: '',
  brevo_api_key: '',
  email_from_address: '',
  email_from_name: ''
};

export const AppConfigContext = createContext(initialState);

export const AppConfigProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppConfigReducer, initialState);
  const { alert, success } = useContext(ModalContext);

  const getAppConfig = () => {
    AppConfigService.getAppConfig().then((res) => {
      const { config } = res.data;
      dispatch({ type: SET_CONFIG, payload: config });
    });
  };

  const getAdminAppConfig = () => {
    AppConfigService.getAdminAppConfig().then((res) => {
      const { config } = res.data;
      dispatch({ type: SET_CONFIG, payload: config });
    });
  };

  const setAppConfigProperty = (key, value) => {
    dispatch({ type: SET_PROPERTY, payload: { key, value} });
  }

  const updateAppConfig = (data) => {
    return new Promise((resolve, reject) => {
      AppConfigService.updateAppConfig(data).then((res) => {
        const { config } = res.data;
        dispatch({ type: SET_CONFIG, payload: config });
  
        success('¡Configuración Guardada!');
        resolve()
      }).catch(err => {
        alert(err);
        reject()
      });
    });
  }

  return (
    <AppConfigContext.Provider value={{
      ...state, 
      getAppConfig,
      getAdminAppConfig,
      updateAppConfig,
      setAppConfigProperty
    }}>
      {children}
    </AppConfigContext.Provider>
  );
};
