import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { ModalContext } from "../../context/ModalContext";
import { PackagesContext } from "../../context/PackageContext";
import { PurchasesContext } from "../../context/PurchasesContext";
import RevokeForm from "../../components/purchases/RevokeForm";
import { getValue, hideModal } from "../../utils";
import CancelForm from "../../components/purchases/CancelForm";
import PurchasesTable from "../../components/purchases/PurchasesTable";
import InvoicesTable from "../../components/invoices/InvoicesTable";
import PanelTitle from "../../components/global/PanelTitle";
import AddPurchaseForm from "../../components/purchases/AddPurchaseForm";
import InvoiceForm from "../../components/invoices/InvoiceForm";
import { UserContext } from "../../context/UserContext";
import { PaymentMethodsContext } from "../../context/PaymentMethodsContext";
import { NotesContext } from "../../context/NotesContext";
import CustomerData from "../../components/customers/CustomerData";
import CustomerActions from "../../components/customers/CustomerActions";
import { navigate } from "@reach/router";
import { AppConfigContext } from "../../context/AppConfigContext";
import ReservacionesTable from "../../components/reservaciones/ReservacionesTable";
import NotesOffcanvas from "../../components/notes/NotesOffcanvas";
import AvailableClassesPanel from "../../components/availableClasses/AvailableClassesPanel";
import OnlineAccessTable from "../../components/customers/OnlineAccessTable";
import useAppConfigValues from "../../hooks/useAppConfigValues";

const AdminSingleUsuario = ({ customer_id }) => {
  const [currentTab, setCurrentTab] = useState('Compras');

  const {
    customer,
    getCustomer,
    clearCustomer,
    extenderAcceso,
    deleteCustomer,
  } = useContext(CustomerContext);
  const { paquetes, getAllPaquetes } = useContext(PackagesContext);
  const { getNotesByCustomer } = useContext(NotesContext);

  const { clearModal, modalComponent, offcanvasComponent } = useContext(ModalContext);

  const { videosEnabled, singleClassEnabled } = useAppConfigValues();

  const { payment_methods, getPaymentMethods } = useContext(
    PaymentMethodsContext
  );

  const { cancelPurchase, revokePurchase, editExpiration } =
    useContext(PurchasesContext);

  const { user } = useContext(UserContext);

  useEffect(() => {
    getPaymentMethods();
    getCustomer(customer_id);
    getAllPaquetes();
    getNotesByCustomer(customer_id);

    return clearCustomer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id]);

  const getViewData = () => {
    getCustomer(customer_id);
  };

  const purchaseForm = () => {
    modalComponent(
      "Agregar Acceso",
      <AddPurchaseForm
        customer={customer}
        paquetes={paquetes}
        handleCancel={clearModal}
        extenderAcceso={extenderAcceso}
        paymentMethods={payment_methods}
      />
    );
  };

  const invoiceForm = () => {
    modalComponent(
      "Agregar Cargo",
      <InvoiceForm
        purchases={customer.purchases}
        handleCancel={clearModal}
        paymentMethods={payment_methods}
        handleCallback={getViewData}
      />
    );
  };

  const confirmDelete = () => {
    modalComponent(
      "Precaución",
      <div>
        <p>
          ¿Estás segura que deseas eliminar el cliente {customer.name}{" "}
          {customer.last_name} con el correo {customer.email}?
        </p>
        <p>Esta acción NO puede deshacerse.</p>
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-danger"
              onClick={() => deleteCustomer(customer_id)}
            >
              <i className="fa fa-trash"></i> Eliminar Cliente
            </button>
          </div>
          <div className="col-6 text-right">
            <button className="btn btn-link text-secondary" onClick={hideModal}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const confirmRevoke = (purchase) => {
    modalComponent(
      "Precaución",
      <RevokeForm
        purchase={purchase}
        customer_id={customer_id}
        revokePurchase={revokePurchase}
      />
    );
  };

  const confirmCancel = (purchase) => {
    modalComponent(
      "Precaución",
      <CancelForm
        purchase={purchase}
        customer_id={customer_id}
        cancelPurchase={cancelPurchase}
      />
    );
  };

  const renderUsuario = () => {
    if (customer && customer !== null) {
      const isAdmin = ["super_admin", "admin"].includes(user.role);
      return (
        <div className="row">
          <div className="col-12 col-md-6">
            <CustomerData
              customer={customer}
              handleEdit={() =>
                navigate(`/myadmin/customer/${customer_id}/edit`)
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomerActions 
              customer={customer} 
              showTotalPurchases={isAdmin}
            />
          </div>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  const renderTabs = () => {
    const tabs = [
      'Compras',
      'Cargos',
      'Reservaciones',
      'Clases',
      'Accesos'
    ];

    return tabs.map(tab => {
      const active = tab === currentTab ? true : false;

      return(
        <li 
          key={tab}
          className={`nav-item`}
          onClick={() => setCurrentTab(tab)}
        >
          <p className={`nav-link mb-0 ${active ? 'bg-primary' : 'text-dark'} `}>{tab}</p>
        </li>
      )
    })
  }

  const renderComprasSection = () => {
    if(currentTab === 'Compras') {
      return (
        <div className="card p-3 pb-1  my-3">
          <PanelTitle title="Compras" onClick={purchaseForm} />
          <PurchasesTable
            user={user}
            hideColumns={["type", "customer"]}
            editExpiration={editExpiration}
            purchases={customer !== null ? customer.purchases : []}
            confirmRevoke={confirmRevoke}
            confirmCancel={confirmCancel}
          />
        </div>
      )
    }
  }

  const renderCargosSection = () => {
    if(currentTab === 'Cargos') {
      return (
        <div className="card p-3 pb-1  my-3">
          <PanelTitle title="Cargos" onClick={invoiceForm} />
          <InvoicesTable
            user={user}
            customer_id={customer_id}
            invoices={customer !== null ? customer.invoices : []}
          />
        </div>
      )
    }
  }

  const renderReservationsSection = () => {
    if (singleClassEnabled() && currentTab === 'Reservaciones') {
      return (
        <div className="card p-3 pb-1  my-3">
          <PanelTitle title="Reservaciones" hideButton />

          <ReservacionesTable
            reservaciones={customer?.class_reservations}
            deleteCallback={getViewData}
          />
        </div>
      );
    }
  };

  const renderClassesSection = () => {
    if (currentTab === 'Clases') {
      return <AvailableClassesPanel customer_id={customer_id}/>;
    }
  };

  const renderAccesosOnlineTable = () => {
    if(currentTab === 'Accesos' && videosEnabled()){
      return (
        <OnlineAccessTable accesses={customer?.online_accesses}/>
      )
    }
  }

  const handleNotesOffcanvas = () => {
    offcanvasComponent(
      "Notas",
      <NotesOffcanvas 
        customer_id={customer_id}
        handleCallback={() => getNotesByCustomer(customer_id)}
      />
    )
  }

  const renderNotesBtn = () => {
    return(
      <li 
        className={`nav-item`}
        onClick={() => {
          handleNotesOffcanvas();
        }}
      >
        <p className={`nav-link mb-0 text-dark `}>Notas</p>
      </li>
    )
  }

  return (
    <div className="container-fluid">
      <div className="row pb-2 border-bottom mx-0 mb-3 align-items-center">
        <div className="col col-md-6 ps-0">
          <h1>Cliente</h1>
        </div>
        <div className="col col-md-6 pe-0 text-end"></div>
      </div>

      <div className="card mb-3">{renderUsuario()}</div>

      <div className="card p-3 my-3">
        <div className="row mx-auto w-100">
          <div className="col-12 ps-0">
            <ul className="nav nav-pills">
              {renderTabs()}
              {renderNotesBtn()}
            </ul>
          </div>
        </div>
      </div>

      {renderComprasSection()}
      {renderCargosSection()}
      {renderReservationsSection()}
      {renderClassesSection()}
      {renderAccesosOnlineTable()}

      <button className="btn btn-outline-danger mt-5" onClick={confirmDelete}>
        <i className="fa fa-trash"></i> Eliminar Cliente
      </button>
    </div>
  );
};

export default AdminSingleUsuario;
