import { useContext } from "react";
import { AppConfigContext } from "../context/AppConfigContext";
import AdjuntosService from "../services/AdjuntosService";
import { AdjuntosContext } from "../context/AdjuntosContext";
import { PagesContext } from "../context/PagesContext";


const useCrudAppConfig = () => {
  const {
    fileConfigChanged,
    business_name,
    tagline,
    primary,
    accent,
    dark,
    light,
    gray,
    signup_bonus,
    cancel_timeframe_unit,
    cancel_timeframe_value,
    reservation_timeframe_hours,
    reservation_timeframe_minutes,
    files_location,
    head_tracking_codes,
    body_tracking_codes,
    images_inputs,
    stripe_secret,
    stripe_webhook_secret,
    stripe_public_key,
    allow_class_guess,
    location,
    email,
    facebook,
    instagram,
    tiktok,
    twitter,
    youtube,
    pinterest,
    linkedin,
    whatsapp,
    phone,
    whatsapp_widget,
    privacyPage,  
    termsPage, 
    brevo_api_key,
    email_from_address,
    email_from_name,

    setAppConfigProperty,
    updateAppConfig,
  } = useContext(AppConfigContext);

  const { testAdjunto } = useContext(AdjuntosContext);
  const { postPage } = useContext(PagesContext);

  const uploadFiles = async () => {
    const files = [];

    if(images_inputs) {
      await Promise.all(
        images_inputs.map(async (input) => {
          return new Promise(async (resolve, reject) => {
            try {
              if(input.file) {
                const formData = AdjuntosService.getFormData(input.file);
                const fileData = (await AdjuntosService.postAdjunto(formData)).data.file;
                const fileName = `${fileData?.name}.${fileData.type}`;
  
                files.push({
                  key: input.key,
                  fileName
                });
              }
              resolve();
            } catch (error) {
              reject(error);
            }
          })
        })
      );
    }

    return files;
  }

  const saveAppConfigPages = async () => {
    await postPage(privacyPage);
    await postPage(termsPage);
  }



  const saveAppConfig = async () => {
    setAppConfigProperty('loading', true);

    if(fileConfigChanged) {
      const result = await testAdjunto();
      if(!result) return;
    }

    const newFiles = await uploadFiles();

    const data = {
      business_name,
      tagline,
      primary,
      accent,
      dark,
      light,
      gray,
      signup_bonus,
      cancel_timeframe_value,
      cancel_timeframe_unit,
      reservation_timeframe_hours,
      reservation_timeframe_minutes,
      files_location,
      head_tracking_codes,
      body_tracking_codes,
      stripe_secret,
      stripe_webhook_secret,
      stripe_public_key,
      allow_class_guess,
      location,
      email,
      facebook,
      instagram,
      tiktok,
      twitter,
      youtube,
      pinterest,
      linkedin,
      whatsapp,
      phone,
      whatsapp_widget,
      brevo_api_key,
      email_from_address,
      email_from_name,
    }

    newFiles.forEach(file => {
      data[file.key] = file.fileName;
    })

    await updateAppConfig(data);  
    await saveAppConfigPages()
    setAppConfigProperty('loading', false);
  }


  return {
    saveAppConfig
  };
}

export default useCrudAppConfig;